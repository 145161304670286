<template>
  <BaseTableWrapper :table-init="tableInit">
    <h1 class="page-header">Lead Sources</h1>
  </BaseTableWrapper>
</template>

<script>
import BaseTableWrapper from '@/components/BaseTableWrapper.vue'
import BaseActionsRequiresWrapper from '@/components/BaseActionsRequiresWrapper.vue'
import actionsWrapper from '@/utils/BaseActionsWrapper'
import LeadSourcesListDetail from '@/components/LeadSourcesListDetail.vue'
import leadSources from '@/services/leadSources'

const wrappedBaseActions = actionsWrapper(
  BaseActionsRequiresWrapper,
  'leadSource'
)

const columns = [
  {
    _t_id: 'b2cc608c',
    prop: '/',
    text: 'Actions',
    component: wrappedBaseActions,
    sort: false,
    filter: false,
    detail: false,
    type: 'actions',
  },
  {
    _t_id: '9a4b0d9e',
    prop: 'displayName',
    text: 'Display Name',
    sort: true,
    type: 'text',
    sortProp: 'displayName',
    defaultSort: true,
    filter: true,
    filterType: 'contains',
    detail: false,
  },
  {
    _t_id: 'a24ef27e',
    prop: 'internalName',
    text: 'Internal Name',
    sort: true,
    type: 'text',
    sortProp: 'internalName',
    filter: true,
    filterType: 'contains',
    detail: false,
  },
  {
    _t_id: '127fd3a5',
    prop: 'description',
    text: 'Description',
    sort: true,
    type: 'text',
    sortProp: 'description',
    detail: false,
  },
]

export default {
  components: {
    BaseTableWrapper,
  },
  metaInfo() {
    return {
      title: 'Lead Sources',
    }
  },
  data() {
    return {
      isAdmin: false,
      tableInit: {
        id: 'lead-sources-list',
        enableStatusFilterToggle: false,
        enableExport: false,
        enableColumnConfig: false,
        enableSavedViews: false,
        detailKeyId: 'leadSourceId',
        tableId: 'lead_sources_tv_view',
        addNewEnabled: true,
        addNewHandler: () => this.$router.push({ name: 'lead-sources.add' }),
        currentPage: 1,
        perPage: 10,
        isDetailed: false,
        detail: LeadSourcesListDetail,
        columns,
        collection: 'LeadSources',
        action: (params) => leadSources.getLeadSources(params),
      },
    }
  },
}
</script>
